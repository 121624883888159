@ -0,0 +1,179 @@
<template>
  <div style="padding: 20px;">
    <h1>{{ msg }}</h1>
    <div style="font-size:14px">
      <h3>录音时长：{{ recorder && recorder.duration.toFixed(4) }}</h3>
      <van-button type="primary" @click="handleStart">开始录音</van-button>
      <van-button type="info" @click="handlePause">暂停录音</van-button>
      <van-button type="success" @click="handleResume">继续录音</van-button>
      <van-button type="warning" @click="handleStop">停止录音</van-button>
      <br />
      <br />
      <h3>
        播放时长：{{ recorder && (playTime > recorder.duration ? recorder.duration.toFixed(4) : playTime.toFixed(4)) }}
      </h3>
      <van-button type="primary" @click="handlePlay">播放录音</van-button>
      <van-button type="info" @click="handlePausePlay">暂停播放</van-button>
      <van-button type="success" @click="handleResumePlay">继续播放</van-button>
      <van-button type="warning" @click="handleStopPlay">停止播放</van-button>
      <br />
      <br />
      <h3>操作：</h3>
      <van-button type="danger" @click="handleDestroy">销毁录音</van-button>
      <van-button type="primary" @click="upload">提交录音</van-button>
      <!-- <br />
      <br />
      <van-field
        v-model="answerText"
        rows="5"
        autosize
        label="答案文本:"
        type="textarea"
        placeholder="请上传语音转换文本，或者直接输入您的回答"
        style="border: 1px solid #DBDBDB;"
      />

      <van-button type="primary" @click="onSubmit" :disabled="answerText.length == 0">提交答案</van-button> -->
      <van-overlay :show="loadShow">
        <div class="wrapper">
            <van-loading size="24px" color="#0094ff" vertical type="spinner">音频上传中...</van-loading>
        </div>
      </van-overlay>
      <van-overlay :show="loadShow2">
        <div class="wrapper">
            <van-loading size="24px" color="#0094ff" vertical type="spinner">音频转文字中...</van-loading>
        </div>
      </van-overlay>
    </div>
  </div>
</template>

<script>
import Recorder from 'js-audio-recorder'
import axios from 'axios'
import { Toast } from 'vant';
const lamejs = require('lamejs')

export default {
  name: 'MyRecorder',
  components: {
    [Toast.name]: Toast,
  },
  props: {
    msg: String,
    qid: {
      type: String,
      default: "0"
    },
    answerText: {
      type: String,
      default: ""
    },
    isShow: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      recorder: null,
      playTime: 0,
      timer: null,
      src: null,
      answerPath: "",
      loadShow: false,
      loadShow2: false,
      fileExt: "wav",
    }
  },
  created() {
    this.recorder = new Recorder({
      sampleBits: 16, // 采样位数，支持 8 或 16，默认是 16
      sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，Chrome 是 48000
      numChannels: 1, // 声道数，支持 1 或 2， 默认是 1
    })
  },
  methods: {
    // 开始录音
    handleStart() {
      this.recorder = new Recorder({
        sampleBits: 16, // 采样位数，支持 8 或 16，默认是 16
        sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，Chrome 是 48000
        numChannels: 1, // 声道数，支持 1 或 2， 默认是 1
      }),
        Recorder.getPermission().then(() => {
          console.log('开始录音')
          this.recorder.start() // 开始录音
        }, (error) => {
            Toast("请先允许该网页使用麦克风")
          console.log(`${error.name} : ${error.message}`)
        })
    },
    handlePause() {
      console.log('暂停录音')
      this.recorder.pause() // 暂停录音
    },
    handleResume() {
      console.log('恢复录音')
      this.recorder.resume() // 恢复录音
    },
    handleStop() {
      console.log('停止录音')
      this.recorder.stop() // 停止录音
    },
    handlePlay() {
      console.log('播放录音')
      console.log(this.recorder)
      this.recorder.play() // 播放录音

      // 播放时长
      this.timer = setInterval(() => {
        try {
          this.playTime = this.recorder.getPlayTime()
        } catch (error) {
          this.timer = null
        }
      }, 100)
    },
    handlePausePlay() {
      console.log('暂停播放')
      this.recorder.pausePlay() // 暂停播放

      // 播放时长
      this.playTime = this.recorder.getPlayTime()
      this.time = null
    },
    handleResumePlay() {
      console.log('恢复播放')
      this.recorder.resumePlay() // 恢复播放

      // 播放时长
      this.timer = setInterval(() => {
        try {
          this.playTime = this.recorder.getPlayTime()
        } catch (error) {
          this.timer = null
        }
      }, 100)
    },
    handleStopPlay() {
      console.log('停止播放')
      this.recorder.stopPlay() // 停止播放

      // 播放时长
      this.playTime = this.recorder.getPlayTime()
      this.timer = null
    },
    handleDestroy() {
      console.log('销毁实例')
      this.recorder.destroy() // 销毁实例
      this.timer = null
    },
    downloadPCM() {
      console.log('下载PCM格式数据')
      // 注：使用该方法会默认调用 stop() 方法
      this.recorder.downloadPCM("record" + new Date().getTime())
    },
    downloadWAV() {
      console.log('下载WAV格式数据')
      // 注：使用该方法会默认调用 stop() 方法
      this.recorder.downloadWAV("record" + new Date().getTime())
    },

    upload() {
      console.log(this.recorder)
      if (parseInt(this.recorder.duration) <= 0) {
        Toast.fail("请录制有效时常后上传")
        return
      }
        this.loadShow = true
        // const blob = this.convertToMp3(this.recorder.getWAV()) // 获取 WAV 格式音频数据
        const blob = this.recorder.getWAVBlob() // 获取 WAV 格式音频数据
        // const blob = this.recorder.getPCMBlob() // 获取 WAV 格式音频数据
        // this.fileExt = "pcm"
        //使用FormData用multipart/form-data表单上传文件
        //或者将blob文件用FileReader转成base64纯文本编码，使用普通application/x-www-form-urlencoded表单上传
        var form=new FormData();
        form.append("myfile", blob, "recorder."+this.fileExt); //和普通form表单并无二致，后端接收到upfile参数的文件，文件名为recorder.mp3
        form.append("subpath","audio"); //其他参数
        console.log(form)
        var xhr=new XMLHttpRequest();
        xhr.open("POST", "https://qcopsapi.thvai.com/console/api/file/upload");
        xhr.onreadystatechange=()=>{
            console.log(xhr)
            if(xhr.readyState==4){
                if(xhr.status==200){
                    var res = JSON.parse(xhr.response)
                    console.log(res.data)
                    var filepath = res.data.filepath.split("storage/")
                    this.answerPath = filepath[1]
                    this.onSubmit()
                    // 语音转的文字作为问题去问gpt
                    // state.currentMessage = res.data.content
                    // sendMessage(blob)
                    console.log("上传成功");
                }else{

                    this.loadShow = false
                    Toast.fail("上传失败")
                    console.error("上传失败"+xhr.status);
                }
            }
        }
        xhr.send(form);
    },
    async audio2Text() {
        const audioData = {
            "speech_url": "https://qcopsapi.thvai.com/console/api/file/showimg?subpath="+this.answerPath, //音频全路径
            "format": this.fileExt,
            "pid": 80001,
        }
        this.loadShow2 = true

        await this.$req({
            pathname: "https://qcopsapi.thvai.com/console/api/",
            url: 'longaudio2text',
            data: audioData,
            timeout: 600 * 1000,
            callback: (data) => {
                console.log(data)
                this.answerText = data.join(" ")
                this.loadShow2 = false
                Toast.success("转换成功，请核验")
                // state.currentMessage = data.data.join('')
            },
            errorCallback: () => {
                this.loadShow2 = false
                Toast.fail("转换失败，请重试")
            }
        })
    },
    // wav转换成mp3格式
    convertToMp3(wavDataView) {
      // 获取wav头信息
      const wav = lamejs.WavHeader.readHeader(wavDataView); // 此处其实可以不用去读wav头信息，毕竟有对应的config配置
      const { channels, sampleRate } = wav;
      const mp3enc = new lamejs.Mp3Encoder(channels, sampleRate, 128);
      // 获取左右通道数据
      const result = this.recorder.getChannelData()
      const buffer = [];

      const leftData = result.left && new Int16Array(result.left.buffer, 0, result.left.byteLength / 2);
      const rightData = result.right && new Int16Array(result.right.buffer, 0, result.right.byteLength / 2);
      const remaining = leftData.length + (rightData ? rightData.length : 0);

      const maxSamples = 1152;
      for (let i = 0; i < remaining; i += maxSamples) {
        const left = leftData.subarray(i, i + maxSamples);
        let right = null;
        let mp3buf = null;

        if (channels === 2) {
          right = rightData.subarray(i, i + maxSamples);
          mp3buf = mp3enc.encodeBuffer(left, right);
        } else {
          mp3buf = mp3enc.encodeBuffer(left);
        }

        if (mp3buf.length > 0) {
          buffer.push(mp3buf);
        }
      }
      const enc = mp3enc.flush();
      if (enc.length > 0) {
        buffer.push(enc);
      }
      return new Blob(buffer, { type: 'audio/mp3' });
    },

    onSubmit() {
        // if (this.answerText == "") {
        //     Toast.fail("答案文本不可为空")
        // }

        this.$req({
            url: 'question/answer',
            data: {
                question_id: this.qid,
                answer_path: this.answerPath,
                answer_text: this.answerText,
            },
            callback: (data) => {
                console.log(data)
                this.loadShow = false
                Toast.success("提交成功")
                setTimeout(()=>{
                  this.$router.push({
                    name: 'Qlist',
                  })
                }, 600)
                // state.currentMessage = data.data.join('')
            },
            errorCallback: () => {
                this.loadShow = false
                Toast.fail("提交失败，请重试")
            }
        })
    },

    uploadRecord() {
      if (this.recorder == null || this.recorder.duration === 0) {
        Toast.fail("请先录音")
        return false
      }
      this.recorder.pause() // 暂停录音
      this.timer = null
      console.log('上传录音') // 上传录音

      const blob = this.recorder.getPCMBlob() // 获取 pcm 格式音频数据
      const newBlob = new Blob([blob])
      // 此处获取到 blob 对象后需要设置 fileName 满足项目上传需求，这里选择把 blob 包装成 file 塞入 formData
      const fileOfBlob = new File([newBlob], new Date().getTime() + '.pcm')

      const formData = new FormData()
      formData.append('file', fileOfBlob)
      const url = window.URL.createObjectURL(fileOfBlob)
      this.src = url

      // 上传录音
    //   axios.post('http://localhost:8087/upload', formData, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data'
    //     }
    //   }).then(response => {
    //     console.log('上传成功', response.data);
    //   }).then(res => {
    //     console.log(res.data.data[0].url)
    //   })
    //     .catch(error => {
    //       console.error('上传失败', error);
    //     })
    },
  }
}
</script>
<style lang="scss">
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
</style>