<template>
  <div class="answer">
    <div class="answer-tip">
      <div>回答时常限制：3-5分钟</div>
      <div style="flex: 1"></div>
      <van-count-down v-if="answerStart" :time="time" />
    </div>
    <div class="answer-question">
      问题： {{ detail.question }}
    </div>
    <div class="answer-audio">
      <my-recorder :qid="questionId" :answerText="detail.answer"></my-recorder>
    </div>
  </div>
</template>

<script>
// import AudioEnter from "@/components/AudioEnter.vue"
import MyRecorder from '@/components/MyRecorder.vue'
//必须引入的核心
import Recorder from 'recorder-core';
//引入mp3格式支持文件；如果需要多个格式支持，把这些格式的编码引擎js文件放到后面统统引入进来即可
import 'recorder-core/src/engine/mp3';
import 'recorder-core/src/engine/mp3-engine';
//录制wav格式的用这一句就行
import 'recorder-core/src/engine/wav';
//可选的插件支持项，这个是波形可视化插件
import 'recorder-core/src/extensions/waveview';

export default {
  name: 'Answer',
  components: {
    MyRecorder
    // AudioEnter
  },
  data: () => {
    return {
      questionId: 0,
      detail: {},
      time: 5 * 60 * 1000,
      answerStart: false,
      isShow: true,
      rec: null,
      recBlob: null,
      wave: null,
      recwave: null,
    }
  },
  mounted: function () {
    this.questionId = this.$route.query.question_id || 0
    this.getDetail()
    console.log(navigator)
    // // 获取DOM元素
    // const recordButton = document.getElementById("recordButton");
    // const stopButton = document.getElementById("stopButton");

    // // 定义变量
    // let mediaRecorder;
    // let audioChunks = [];

    // // 获取用户的麦克风设备
    // navigator.mediaDevices.getUserMedia({ audio: true })
    //   .then(function(stream) {
    //     // 创建一个MediaRecorder对象来录制音频
    //     mediaRecorder = new MediaRecorder(stream);

    //     // 开始录制音频
    //     recordButton.addEventListener("click", function() {
    //       mediaRecorder.start();
    //     });

    //     // 停止录制音频
    //     stopButton.addEventListener("click", function() {
    //       mediaRecorder.stop();
    //     });

    //     // 监听音频数据的录制
    //     mediaRecorder.addEventListener("dataavailable", function(event) {
    //       audioChunks.push(event.data);
    //     });

    //     // 停止录制音频并播放
    //     mediaRecorder.addEventListener("stop", function() {
    //       // 将录制的音频数据保存为Blob对象
    //       const audioBlob = new Blob(audioChunks, { type: "audio/ogg" });

    //       // 播放录制的音频
    //       const audioElement = new Audio(URL.createObjectURL(audioBlob));
    //       audioElement.play();
    //     });
    //   })
    //   .catch(function(error) {
    //     console.log("发生错误：" + error);
    //   });
  },
  methods: {
    getDetail () {
      this.$req({
        url: 'question/detail',
        data: {
          question_id: this.questionId,
        },
        callback: (data) => {
          console.log(data)
          this.detail = data.detail || []
          this.recOpen()
        }
      })

    },

    recOpen() {
      //创建录音对象
      this.rec = Recorder({
        type: 'wav', //录音格式，可以换成wav等其他格式
        sampleRate: 16000, //录音的采样率，越大细节越丰富越细腻
        bitRate: 16, //录音的比特率，越大音质越好
        onProcess: (
          buffers,
          powerLevel,
          bufferDuration,
          bufferSampleRate,
          newBufferIdx,
          asyncEnd,
        ) => {
          //录音实时回调，大约1秒调用12次本回调
          //可实时绘制波形，实时上传（发送）数据
          if (this.wave) {
            this.wave.input(buffers[buffers.length - 1], powerLevel, bufferSampleRate);
          }
        },
      });
      if (!this.rec) {
        alert('当前浏览器不支持录音功能！');
        return;
      }
      //打开录音，获得权限
      this.rec.open(
        () => {
          console.log('录音已打开');
          if (this.recwave) {
            //创建音频可视化图形绘制对象
            this.wave = Recorder.WaveView({ elem: this.recwave });
          }
        },
        (msg, isUserNotAllow) => {
          //用户拒绝了录音权限，或者浏览器不支持录音
          console.log((isUserNotAllow ? 'UserNotAllow，' : '') + '无法录音:' + msg);
        },
      );
    },

    // 开始录音
    recStart() {
      if (!this.rec) {
        console.error('未打开录音');
        return;
      }
      this.rec.start();
      console.log('已开始录音');
    },

    // 结束录音
    recStop() {
      if (!this.rec) {
        console.error('未打开录音');
        return;
      }
      this.rec.stop(
        (blob, duration) => {
          //blob就是我们要的录音文件对象，可以上传，或者本地播放
          this.recBlob = blob;
          //简单利用URL生成本地文件地址，此地址只能本地使用，比如赋值给audio.src进行播放，赋值给a.href然后a.click()进行下载（a需提供download="xxx.mp3"属性）
          const localUrl = (window.URL || window.webkitURL).createObjectURL(blob);
          console.log('录音成功', blob, localUrl, '时长:' + duration + 'ms');
          this.upload(blob); //把blob文件上传到服务器
          this.rec.close(); //关闭录音，释放录音资源，当然可以不释放，后面可以连续调用start
          this.rec = null;
        },
        (err) => {
          console.error('结束录音出错：' + err);
          this.rec.close(); //关闭录音，释放录音资源，当然可以不释放，后面可以连续调用start
          this.rec = null;
        },
      );
    },

    // 上传录音
    upload(blob) {
      //使用FormData用multipart/form-data表单上传文件
      //或者将blob文件用FileReader转成base64纯文本编码，使用普通application/x-www-form-urlencoded表单上传
      // const form = new FormData();
      // form.append('upfile', blob, 'recorder.mp3'); // 和普通form表单并无二致，后端接收到upfile参数的文件，文件名为recorder.mp3
      // form.append('key', 'value'); // 其他参数
      // var xhr = new XMLHttpRequest();
      // xhr.open('POST', '/upload/xxxx');
      // xhr.onreadystatechange = () => {
      //   if (xhr.readyState == 4) {
      //     if (xhr.status == 200) {
      //       console.log('上传成功');
      //     } else {
      //       console.error('上传失败' + xhr.status);
      //     }
      //   }
      // };
      // xhr.send(form);
      // 也可以写自己的上传函数
      // uploadService(blob, 'zengjiaqi_test.wav');
    },

    // 本地播放录音
    recPlay() {
      //本地播放录音试听，可以直接用URL把blob转换成本地播放地址，用audio进行播放
      const localUrl = URL.createObjectURL(this.recBlob);
      const audio = document.createElement('audio');
      audio.controls = true;
      document.body.appendChild(audio);
      audio.src = localUrl;
      audio.style = "display: none"
      audio.play(); //这样就能播放了
      //注意不用了时需要revokeObjectURL，否则霸占内存
      setTimeout(function () {
        URL.revokeObjectURL(audio.src);
      }, 5000);
    },

    closeAudio() {
      this.isShow = false;
      // 处理其他逻辑......
    }
  }
}
</script>

<style lang="less">
.answer {
  padding: 16px 12px;
  text-align: left;
  font-size: 18px;
  .answer-tip {
    padding: 12px 0;
    color: #ff976a;
    display: flex;
  }
  .answer-question {
    padding: 10px 0;
    border-bottom: 1px solid #DBDBDB;
  }
  .van-count-down {
    font-size: 18px;
    color: #ee0a24;
  }
}
</style>